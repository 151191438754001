<template>
  <!--begin::Quotation Listing-->
  <div class="quotation-template">
    <PageHeaderCount
      moduleType="quotation"
      :dataLoading="dataLoading"
      :statusList="statusList"
      allkey="all_quotations"
      countkey="quotation_status_count"
    ></PageHeaderCount>
    <ListingTemplate
      :customClass="'quotation-listing'"
      v-if="getPermission('quotation:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-flex class="pt-0 my-auto">
                <h1 class="form-title margin-auto selected-rows-text">
                  {{ totalSelected }} Selected
                </h1>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('quotation:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(more, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="updateBulkAction(more.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              more.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ more.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>

                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex class="pt-0">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="typeList"
                    v-model="quoteType"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                    v-on:change="filterQuote($event)"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <!-- <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle> -->
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          {{ item.status_count }}
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <!-- <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle> -->
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_quotations
                          }}</template>
                          <template v-else>{{
                            item.quotation_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-flex>
              <v-flex
                class="pt-0 pr-0 justify-flex-end d-flex quotation-listing-button-right"
              >
                <v-checkbox
                  color="cyan"
                  :disabled="archiveLoading"
                  :input-value="$route.query?.archived ? true : false"
                  dense
                  hide-details
                  label="Show Archived"
                  class="mr-4"
                  v-on:change="showArchived"
                />
                <template v-if="getPermission('quotation:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button custom-btn-color white--text"
                    :to="getDefaultRoute('quotation.create')"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                  <!--  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    v-on:click="exportQuotation"
                    color="cyan white--text"
                  >
                    <v-icon dark left>mdi-database-export</v-icon> Export
                  </v-btn> -->
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <!-- <v-btn
                  v-if="!isEngineerChannel()"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'quotation',
                        },
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button pr-0"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn> -->
                <v-menu
                  content-class="custom-menu-list"
                  offset-y
                  left
                  v-if="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        :class="{ 'line-active': item.sort }"
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" small left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                          <template v-if="item.sort">
                            <v-icon
                              v-if="item.sort == 'asc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-ascending</v-icon
                            >
                            <v-icon
                              v-if="item.sort == 'desc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-descending</v-icon
                            >
                          </template>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-menu
                  max-height="400"
                  max-width="260"
                  offset-y
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                    v-on:change="updateVisible()"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          color="cyan"
                          v-on:change="updateVisible()"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <v-menu offset-y left v-if="false">
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          depressed
                          :disabled="dataLoading"
                          class="ml-2 custom-bold-button"
                          color="cyan white--text"
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          {{ entityObject.name }}
                        </v-btn>
                      </template>
                      <span>Entity</span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in entityList"
                      :key="index"
                      link
                      v-on:click="updateEntity(item)"
                    >
                      <v-list-item-title class="font-weight-600">{{
                        item.name
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <PageTips v-if="false" module="quotation"></PageTips>
              </v-flex>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Quotation"
          :basicSearchFields="[
            'barcode',
            'sdo_barcode',
            'reference',
            'job_title',
            'sales',
            'prepared_by',
            /* 'attention', */
            'company_name',
          ]"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{
                        'checkbox-width-limit': cols.checkbox,
                        'quote-customer-row': cols.field == 'company_info',
                      }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="rowData.length > 0">
                  <tr
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                    class="quotation-listing-row"
                    :class="{
                      'row-archived': data?.is_archived,
                    }"
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                          'quote-customer-row': cols.field == 'company_info',
                        }"
                        v-on:click="
                          Number(cols.checkbox) == 0
                            ? rowClicked(data)
                            : 'javascript:void(0)'
                        "
                      >
                        <template
                          v-if="cols.checkbox && Number(cols.checkbox) == 1"
                        >
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'related_tag'">
                          <template v-if="data?.quote_type == '1'">
                            <v-chip
                              label
                              small
                              color="light-green darken-3"
                              text-color="white"
                              class="text-center text-uppercase font-weight-600"
                              >New Door</v-chip
                            >
                          </template>
                          <template v-if="data?.quote_type == '3'">
                            <v-chip
                              label
                              small
                              color="teal darken-4"
                              text-color="white"
                              class="text-center text-uppercase font-weight-600"
                              >Repair</v-chip
                            >
                          </template>
                          <template v-if="data?.quote_type == '2'">
                            <br />
                            <v-chip
                              label
                              small
                              color="orange darken-4 white--text"
                              text-color="white"
                              class="text-center text-uppercase font-weight-600"
                              >Maintenance</v-chip
                            >
                            <br />
                            <v-chip
                              label
                              small
                              :color="
                                maintenanceType(data.contract_type)?.color
                              "
                              class="mt-1 text-center text-uppercase font-weight-600"
                              >{{
                                maintenanceType(data.contract_type)?.text
                              }}</v-chip
                            >
                          </template>
                          <template v-if="data?.invoice_relation_count > 0">
                            <br />
                            <v-chip
                              label
                              small
                              color="green"
                              class="mt-1 text-center text-uppercase font-weight-600 text-white"
                              >Invoiced</v-chip
                            >
                          </template>
                        </template>
                        <!--  <template v-else-if="cols.field == 'rating'">
                          <p class="m-0 d-flex">
                            <template v-if="data.rating"
                              ><v-rating
                                readonly
                                dense
                                v-model.trim="data.rating"
                                background-color="orange lighten-3"
                                color="orange"
                              ></v-rating
                            ></template>
                            <template v-else
                              ><em class="text-muted">No Rating</em></template
                            >
                          </p>
                        </template> -->
                        <template v-else-if="cols.field == 'new_barcode'">
                          <v-chip
                            v-if="
                              data?.barcode &&
                              data?.status != 8 &&
                              data?.status != 16
                            "
                            label
                            color="cyan"
                            class="text-white"
                            outlined
                            small
                            >{{ data.barcode }}
                          </v-chip>
                          <div v-else class="font-weight-700 text-center">
                            --
                          </div>

                          <v-chip
                            label
                            color="blue"
                            class="text-white mt-1"
                            v-if="
                              data?.revise &&
                              data?.status != 8 &&
                              data?.status != 16 && data.is_revised == 0
                            "
                            outlined
                            small
                            ><!-- {{ data?.revise_relation?.barcode }} -->
                            Revision</v-chip
                          >
                            <div class="mb-1">
                              <template v-if="data.is_revised  &&
                              data?.status != 8 &&
                              data?.status != 16">
                                <v-tooltip
                                  top
                                  content-class="custom-top-tooltip"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                      v-bind="attrs"
                                      v-on="on"
                                      color="red darken-4 white--text"
                                      class="text-white mt-1"
                                      label
                                      small
                                    >
                                      REVISED
                                    </v-chip>
                                  </template>
                                  <span>Quotation is Revised</span>
                                </v-tooltip>
                              </template>
                            </div>
                        </template>
                        <template v-else-if="cols.field == 'converted_by'">
                          <v-chip
                            v-if="data?.converted_by > 0"
                            label
                            color="red"
                            class="text-white"
                            outlined
                            small
                            v-on:click.prevent.stop="
                              routeToDetailLink(
                                data?.converted_by,
                                data?.converted_type
                              )
                            "
                            >{{ data?.converted_form?.barcode }}
                          </v-chip>
                          <div v-else class="font-weight-700 ml-5">--</div>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <div
                            style="position: relative"
                            class="d-flex flex-column flex-wrap align-start"
                          >
                            <Barcode
                              v-if="
                                data?.barcode &&
                                (data?.status == 8 || data?.status == 16)
                              "
                              route="quotation.detail"
                              :barcode="data.barcode"
                              :id="data.id"
                            ></Barcode>
                            <div v-else class="font-weight-700 text-center">
                              --
                            </div>
                            <v-tooltip
                              right
                              content-class="custom-right-tooltip"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  v-bind="attrs"
                                  v-on="on"
                                  v-if="data?.sdo_barcode"
                                  label
                                  color="orange darken-2"
                                  class="text-white"
                                  outlined
                                  small
                                  >{{ data.sdo_barcode }}
                                </v-chip>
                              </template>
                              <span>SDO #</span>
                            </v-tooltip>
                            <v-chip
                              label
                              color="blue"
                              class="text-white mt-1"
                              v-if="
                                data?.revise &&
                                (data?.status == 8 || data?.status == 16) &&  data.is_revised == 0
                              "
                              outlined
                              small
                              ><!-- {{ data?.revise_relation?.barcode }} -->
                              Revision</v-chip
                            >
                              <div class="mb-1">
                              <template v-if="data.is_revised &&
                                (data?.status == 8 || data?.status == 16)">
                                <v-tooltip
                                  top
                                  content-class="custom-top-tooltip"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                      v-bind="attrs"
                                      v-on="on"
                                       color="red darken-4 white--text"
                                       class="text-white mt-1"
                                      label
                                      small
                                    >
                                      REVISED
                                    </v-chip>
                                  </template>
                                  <span>Quotation is Revised</span>
                                </v-tooltip>
                              </template>
                            </div>
                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  style="
                                    position: absolute;
                                    top: 7px;
                                    right: -25px;
                                  "
                                  v-if="data?.has_tool == 1"
                                  color="green darken-4"
                                  small
                                  >mdi-tools</v-icon
                                >
                              </template>
                              <span>Tools</span>
                            </v-tooltip>
                          </div>

                          <v-chip
                            label
                            color="orange"
                            class="text-white p-3"
                            v-if="false"
                            outlined
                            small
                            >{{ data.contract.barcode }}</v-chip
                          >
                          <!--  <Barcode
                              v-if="data.contract"
                              route="contract.detail"
                              :barcode="data.contract.barcode"
                              :id="data.contract.id"
                            ></Barcode> -->
                        </template>
                        <template v-else-if="cols.field == 'date'">
                          <p class="m-0" v-if="data.date">
                            <b>Created: </b> {{ formatDate(data.date) }}
                          </p>
                          <!-- <p
                            class="m-0"
                            v-if="data.open_till"
                          >
                            <b>Open Till: </b> {{ formatDate(data.open_till) }}
                          </p> -->
                          <p class="m-0" v-if="data.sent_date">
                            <b>Sent: </b> {{ formatDate(data.sent_date) }}
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'job_title'">
                          <div class="quotation-listing-customer">
                            <p class="m-0">
                              <b>Title: </b>{{ data.job_title }}
                            </p>
                            <p v-if="false" class="m-0">
                              <b>Visit Count: </b>
                              <template
                                v-if="data && data.ticket_multiple_count > 0"
                              >
                                <span
                                  class="ml-1 count-radius bg-light-success"
                                  >{{ data.ticket_multiple_count }}</span
                                >
                              </template>
                              <span
                                v-else
                                style="
                                  background-color: #93959f !important;
                                  color: white;
                                "
                                class="ml-1 count-radius white-text"
                                >0</span
                              >
                            </p>
                            <p class="m-0-two-line" v-if="data?.full_address">
                              <b>Company Address: </b>
                              <template v-if="data.full_address"
                                >{{ data.full_address }}
                              </template>
                              <em class="text-muted" v-else>
                                no company address
                              </em>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'site_info'">
                          <div class="quotation-listing-customer">
                            <p
                              class="m-0-two-line"
                              v-if="data?.property_full_address"
                            >
                              <template v-if="data?.property_full_address"
                                >{{ data?.property_full_address }}
                              </template>
                              <em v-else class="text-muted">
                                no site location</em
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'basic_detail'">
                          <div class="quotation-listing-customer">
                            <p v-if="false" class="m-0">
                              <b>Reference: </b>
                              <template v-if="data.reference">{{
                                data.reference
                              }}</template>
                              <template v-else
                                ><em class="text-muted"
                                  >No Reference</em
                                ></template
                              >
                            </p>
                            <p class="m-0">
                              <b>Sales By: </b>
                              <template v-if="data?.sales_user?.display_name">{{
                                data?.sales_user?.display_name
                              }}</template>
                              <template v-else
                                ><em class="text-muted"
                                  >No Sales By</em
                                ></template
                              >
                            </p>
                            <p class="m-0">
                              <b>Quote by: </b>
                              <template
                                v-if="data?.prepared_user?.display_name"
                                >{{
                                  data?.prepared_user?.display_name
                                }}</template
                              >
                              <template v-else
                                ><em class="text-muted"
                                  >No Quote by</em
                                ></template
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'status'">
                          <div class="quotation-listing-status">
                            <div
                              class="d-flex flex-column flex-wrap justify-center align-start"
                            >
                              <v-chip
                                label
                                small
                                :color="data?.qt_status_color"
                                :text-color="data?.qt_status_textcolor"
                                class="ml-2 text-uppercase font-weight-600"
                              >
                                {{ data?.qt_status_text }}
                              </v-chip>

                              <v-chip
                                v-if="data?.is_archived"
                                label
                                small
                                color="red text-white"
                                class="my-2 ml-2 text-uppercase font-weight-600"
                              >
                                Archived
                              </v-chip>
                              <!--   <v-chip
                                v-if="data?.invoice_converted == 1"
                                label
                                small
                                color="green"
                                text-color="white"
                                class="my-2 ml-2 text-uppercase font-weight-600"
                                >Converted to Invoice
                              </v-chip>
                              <v-chip
                                v-if="false && data?.visit_converted == 1"
                                label
                                small
                                color="orange"
                                text-color="white"
                                class="ml-2 text-uppercase font-weight-600"
                                >Converted to Visit
                              </v-chip> -->
                              <v-chip
                                v-if="data?.is_signed == 1"
                                label
                                small
                                color="red darken-4 white--text"
                                text-color="white"
                                class="my-2 ml-2 text-uppercase font-weight-600"
                                >Signed
                              </v-chip>
                            </div>
                            <div v-if="false" class="mb-1">
                              <template v-if="data.is_contract">
                                <v-tooltip
                                  top
                                  content-class="custom-top-tooltip"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                      v-bind="attrs"
                                      v-on="on"
                                      color="green lighten-1 white--text"
                                      class="mx-2 custom-status font-weight-600 custom-grey-border d-inline-table d-inline text-uppercase justify-center font-10"
                                      label
                                      small
                                    >
                                      CONTRACT
                                    </v-chip>
                                  </template>
                                  <span>Contract</span>
                                </v-tooltip>
                              </template>
                            </div>
                         <!--    <div class="mb-1">
                              <template v-if="data.is_revised">
                                <v-tooltip
                                  top
                                  content-class="custom-top-tooltip"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                      v-bind="attrs"
                                      v-on="on"
                                      color="red lighten-4"
                                      class="mx-2 custom-status font-weight-600 custom-grey-border d-inline-table d-inline text-uppercase justify-center font-10"
                                      label
                                      small
                                    >
                                      REVISED
                                    </v-chip>
                                  </template>
                                  <span>Quotation is Revised</span>
                                </v-tooltip>
                              </template>
                            </div> -->
                           
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'company_info'">
                          <div class="quotation-listing-customer">
                            <p class="m-0">
                              <b>Display Name: </b>
                              {{
                                data &&
                                data.customer &&
                                data.customer.display_name
                              }}
                              <!-- {{ data.customer.display_name }} -->
                            </p>
                            <p class="m-0">
                              <b>Company: </b>
                              <template
                                v-if="
                                  data &&
                                  data.customer &&
                                  data.customer.company_name
                                "
                                >{{ data.customer.company_name }}</template
                              >
                              <template v-else
                                ><em class="text-muted"
                                  >No company name</em
                                ></template
                              >
                            </p>
                            <p class="m-0" v-if="false">
                              <b>Email: </b>

                              <template
                                v-if="
                                  data.customer && data.customer.company_email
                                "
                                >{{
                                  data.customer && data.customer.company_email
                                }}</template
                              >
                              <template v-else
                                ><em class="text-muted">No email</em></template
                              >
                            </p>
                            <p class="m-0" v-if="false">
                              <b>Phone: </b>

                              <template
                                v-if="
                                  data.customer && data.customer.company_number
                                "
                                >{{
                                  data.customer && data.customer.company_number
                                }}</template
                              >
                              <template v-else
                                ><em class="text-muted">No Phone</em></template
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'amount'">
                          <div class="quotation-listing-amount">
                            <p class="m-0">
                              <b>Sub Total: </b>
                              {{ $accountingJS.formatMoney(data.sub_total) }}
                            </p>
                            <!--  <p class="m-0">
                              <b>Tax: </b>
                              {{ $accountingJS.formatMoney(data.tax_amount) }}
                            </p>
                            <p class="m-0">
                              <b>Total: </b>
                              {{ $accountingJS.formatMoney(data.total) }}
                            </p> -->
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDate(data.added_at) }}
                              {{ formatDateStartTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td :colspan="customThead.length" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no quotation at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Quotation</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <v-col md="6" v-if="false">
                    <v-menu
                      v-model="datePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          filled
                          flat
                          label="Dates Range"
                          hide-details
                          solo
                          clearable
                          v-on:click:clear="dates = []"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :value="formattedDate"
                          color="cyan"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-if="false"
                        color="cyan"
                        range
                        v-model="dates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <v-text-field
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="
                          field == 'sales'
                            ? 'Sales Person'
                            : field == 'customer_detail[value]'
                            ? 'Customer Details[Phone Number]'
                            : getFilterLabel(field, 'Quotation')
                        "
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <ImportTemplate
          :dialog="importDialog"
          sample-url="quotation/import-sample"
          api-url="quotation/import"
          title="Import Quotation"
          v-on:close="importDialog = false"
        ></ImportTemplate>

        <template v-if="entityDialog">
          <EntityDialog
            :entity-dialog="entityDialog"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectEntity="selectEntity"
          ></EntityDialog>
        </template>
        <template v-if="entity && customerDialog">
          <CustomerDialog
            :entity="entity"
            :customerDialog="customerDialog"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomer="selectCustomer"
          ></CustomerDialog>
        </template>
        <template v-if="customerPersonDialog">
          <CustomerPersonDialog
            disabled-auto-select
            :customerPersonDialog="customerPersonDialog"
            :customer="customer"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomerPerson="selectCustomerPerson"
          ></CustomerPersonDialog>
        </template>
        <template v-if="customerPropertyDialog">
          <CustomerPropertyDialog
            disabled-auto-select
            :customerPropertyDialog="customerPropertyDialog"
            :customer="customer"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomerProperty="selectCustomerProperty"
          ></CustomerPropertyDialog>
        </template>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Quotation Listing-->
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import QuotationMixin from "@/core/lib/quotation/quotation.mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH, DOWNLOAD } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import { saveAs } from "file-saver";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
// import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import EntityDialog from "@/view/pages/partials/Select-Entity.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import JwtService from "@/core/services/jwt.service";
import ImportTemplate from "@/view/pages/partials/Import-Template.vue";
//import { map } from "lodash";
import moment from "moment-timezone";
export default {
  mixins: [CommonMixin, ListingMixin, QuotationMixin],
  name: "quotation-list",
  data() {
    return {
      exportLoading: false,
      dates: [],
      pageModule: "quotation-listing",
      routeAPI: "quotation",
      routeName: "quotation",
      routeDetailName: "quotation.detail",
      importDialog: false,
      status: "all",
      statusList: [],
      customThead: [],
      moreActions: [
        {
          title: "Import Quotation(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import",
        },
        /*{
          title: "Export Quotation(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export",
        },*/
        /*{
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export",
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      bulkActions: [
        {
          title: "Mark as Draft",
          icon: "mdi-check-all",
          action: "draft",
        },
        {
          title: "Mark as Accepted",
          icon: "mdi-check-all",
          action: "accepted",
        },
        {
          title: "Mark as Rejected",
          icon: "mdi-check-all",
          action: "rejected",
        },
        {
          title: "Mark as Pending",
          icon: "mdi-email-receive",
          action: "pending",
        },
        /*{
          title: "Send as Email",
          icon: "mdi-email-send",
          action: "sent"
        },*/
        {
          title: "Mark as Cancelled",
          icon: "mdi-check-all",
          action: "cancelled",
        },
        {
          title: "Mark as Closed",
          icon: "mdi-check-all",
          action: "closed",
        },
      ],
    };
  },
  components: {
    PageTips,
    draggable,
    EntityDialog,
    // CustomStatus,
    PageHeaderCount,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
    CustomerDialog,
    CustomerPropertyDialog,
    CustomerPersonDialog,
    ImportTemplate,
  },
  watch: {
    dates: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 1) {
          this.listingSearch["dates"] = param;
        } else {
          this.listingSearch["dates"] = [];
        }
      },
    },
  },
  methods: {
    showArchived(param) {
      this.archiveLoading = true;
      this.$router
        .replace({
          name: "admin.quotation",
          query: {
            ...this.$route.query,
            archived: param ? 1 : undefined,
            t: Number(new Date()),
          },
        })
        .then(() => {
          this.getRows(150);
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    routeToDetailLink(id, type) {
      let route = null;
      if (type == "visit") {
        route = "admin.visit.detail";
      }
      if (type == "enquiry") {
        route = "admin.enquiry.detail";
      }
      this.$router.push({
        name: route,
        params: { id },
      });
    },

    filterQuote(param) {
      this.$router
        .replace({
          name: "admin.quotation",
          query: {
            ...this.$route.query,
            "quote-type": param,
            t: Number(new Date()),
          },
        })
        .then(() => {
          this.getRows(150);
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    selectCustomerDialog() {
      this.customerDialog = true;
    },
    selectCustomerPersonDialog() {
      this.customerPersonDialog = true;
    },
    selectCustomerPropertyDialog() {
      this.customerPropertyDialog = true;
    },
    resetAll() {
      this.entity = 1;
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.entityDialog = false;
      this.customerDialog = false;
      this.customerPersonDialog = false;
      this.customerPropertyDialog = false;
    },
    selectEntity(param) {
      this.entity = param;
      this.closeDialog();
      this.$nextTick(() => {
        this.selectCustomerDialog();
      });
    },
    selectCustomer(param) {
      this.customer = param;
      this.closeDialog();
      this.checkQuotation();
      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkQuotation();
      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerProperty(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkQuotation();
    },

    /* exportQuotation() {
      let downloadURL = process.env.VUE_APP_API_URL + "quotations/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      window.open(downloadURL, "_blank");
    }, */

    exportQuotation() {
      //console.log(row,"rowhhh");
      //console.log(this.searchableArray,"searchableArray");

      let downloadURL = process.env.VUE_APP_API_URL + "quotations/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      //downloadURL.searchParams.append("search", this.queryParams.search);
      this.lodash.map(this.queryParams, function (row, index) {
        downloadURL.searchParams.append(index, row);
      });

      this.lodash.map(this.listingSearch, function (row, index) {
        // console.log(index, "indexdd")

        if (index == "dates") {
          if (row && row[0]) {
            downloadURL.searchParams.append("dates[0]", row[0]);
          }
          if (row && row[1]) {
            downloadURL.searchParams.append("dates[1]", row[1]);
          }
        } else {
          downloadURL.searchParams.append(index, row);
        }

        //  downloadURL.searchParams.append(index, row);
      });
      window.open(downloadURL, "_blank");
    },

    customerRedirect(customerId) {
      this.$router.push(
        this.getDefaultRoute("customer.detail", {
          params: { id: customerId },
        })
      );
    },
    checkQuotation() {
      const _this = this;
      if (_this.customer > 0 && _this.customerProperty > 0) {
        _this.$router.push(
          _this.getDefaultRoute("quotation.create", {
            query: {
              customer: _this.customer ?? undefined,
              property: _this.customerProperty ?? undefined,
            },
          })
        );
      }
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.bulkQuotationExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("quotation");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    updateBulkAction(param) {
      const _this = this;
      switch (param) {
        case "draft":
          _this.bulkQuotationUpdate({
            quotations: _this.selectedRows,
            status: 1,
          });
          break;
        case "accepted":
          _this.bulkQuotationUpdate({
            quotations: _this.selectedRows,
            status: 2,
          });
          break;
        case "rejected":
          _this.bulkQuotationUpdate({
            quotations: _this.selectedRows,
            status: 3,
          });
          break;
        case "pending":
          _this.bulkQuotationUpdate({
            quotations: _this.selectedRows,
            status: 4,
          });
          break;
        case "sent":
          _this.bulkQuotationUpdate({
            quotations: _this.selectedRows,
            status: 5,
          });
          break;
        case "cancelled":
          _this.bulkQuotationUpdate({
            quotations: _this.selectedRows,
            status: 6,
          });
          break;
        case "closed":
          _this.bulkQuotationUpdate({
            quotations: _this.selectedRows,
            status: 7,
          });
          break;
      }
    },
    bulkQuotationUpdate(requestObject) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "quotation",
          data: requestObject,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
        });
    },
    bulkQuotationExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "quotations/export",
        })
        .then(({ data }) => {
          saveAs(data, "quotations-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
  },
  mounted() {
    this.resetAll();
    this.quoteType = this.$route.query["quote-type"] ?? "new";
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Quotation",
      },
    ]);
  },
  computed: {
    ...mapGetters(["contractRecurringScheduleTypeList"]),
    maintenanceType() {
      return (param) => {
        return this.contractRecurringScheduleTypeList.find(
          (row) => row.value == param
        );
      };
    },
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.status = _this.$route.query.status || _this.status;

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
